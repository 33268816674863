export const ModeratorInfluencersSchema = `{
    response{
        uuid
        birthday
        gender
        is_vat_eligible
        vat_key
        stripe_updated_at
        stripe_connect_key
        stripe_balance_available
        stripe_balance_pending
        country {
            name
            iso_3166_2
            flag
        }
        created_at
        updated_at
        channels{
            uuid
            username
            profile_image_url
            followers
            reach
            impressions
            approved_at
            declined_at
            media_count
            active
            channel{
                uuid
                name
                display_name
                description
                icon
                login_link
                created_at
                updated_at
            }
        }
        user{
            uuid
            email
            phone
            first_name
            last_name
        }
        campaign_invitations{
            uuid
        }
        favorite_by_business{
            uuid
        }
    }
    lastPage
    currentPage
    total
    perPage
}`;
export const ModeratorInfluencersChannelsSchema = `{
    response{
        uuid
        gender
        user{
            uuid
            email
            phone
            first_name
            last_name
            avatar
            influencer{
                birthday
            }
        }
        ic_uuid
        ic_username
        ic_profile_image_url
        ic_followers
        ic_impressions
        ic_approved_at
        ic_active
        ch_uuid
        ch_name
        ch_display_name
        ch_description
        ch_icon
        ch_created_at
        ch_updated_at
        ic_media_count
    }
    lastPage
    currentPage
    total
    perPage
}`;

export const InfluencerChannelApplicationSchema = `{
    uuid
    username
    description
    website
    profile_image_url
    followers
    reach
    impressions
    media_count
    reference
    api_key
    active
    analytics_updated_at
    api_updated_at
    api_failed_at
    approved_at
    declined_at
    created_at
    updated_at
}`;
