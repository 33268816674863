import { InfluencerSchema } from "./users.js";
import { ChannelCategoriesSchema } from "./channels.js";

export const CampaignSchema = ` {
    uuid
    name
    status{
        name
    }
    utm_campaign
    utm_medium
    utm_source
    description
    recommendations_description
    promotion_material_description
    product_description
    hidden
    allow_deep_linking
    cost_per_action_percent
    cost_per_action_fixed
    cost_per_click
    commission_type
    max_commission_budget
    url
    min_followers
    max_followers
    participants
    starting_at
    ending_at
    type
    published_at
    created_at
    updated_at
    image_url
    registration_open
    country {
        name
        flag
        iso_3166_2
    }
    business{
        uuid
        company
        display_name
        vat
        phone
        website
        description
        service_fee
        pixel_approved_at
        allowed_active_campaigns
        allowed_active_influencers
        user{
            uuid
            email
            first_name
            last_name
            full_name
        }
        channel_category ${ChannelCategoriesSchema}
    }
    category ${ChannelCategoriesSchema}
    agency{
        uuid
        name
        created_at
        updated_at
        user {
            first_name
            uuid
        }
    }
    active_campaign_request {
        uuid
        note
        cost_per_action_percent
        cost_per_action_fixed
        cost_per_click
        max_commission_budget
        invitation
        accepted_at
        declined_at
        declined_reason
        declined_reason_text
        created_at
        updated_at
        expires_at
        status
    }
}`;

export const CampaignDemoSchema = ` {
    uuid
    name
    status{
        name
    }
    utm_campaign
    utm_medium
    utm_source
    description
    recommendations_description
    promotion_material_description
    product_description
    hidden
    allow_deep_linking
    cost_per_action_percent
    cost_per_action_fixed
    cost_per_click
    max_commission_budget
    url
    min_followers
    max_followers
    participants
    starting_at
    ending_at
    type
    published_at
    created_at
    updated_at
    image_url
    registration_open
    country {
        name
        flag
        iso_3166_2
    }
    business{
        uuid
        company
        display_name
        vat
        phone
        website
        description
        service_fee
        pixel_approved_at
        user{
            uuid
            email
            first_name
            last_name
            full_name
        }
        channel_category ${ChannelCategoriesSchema}
    }
    category ${ChannelCategoriesSchema}
    influencers{
        uuid
        note
        cost_per_action_percent
        cost_per_action_fixed
        cost_per_click
        max_commission_budget
        invitation
        accepted_at
        declined_at
        declined_reason
        declined_reason_text
        created_at
        updated_at
        expires_at
        status
        campaign{
            uuid
            name
        }
        influencer ${InfluencerSchema}
    }
    agency{
        uuid
        name
        created_at
        updated_at
        user {
            first_name
            uuid
        }
    }
    active_campaign_request {
        uuid
        note
        cost_per_action_percent
        cost_per_action_fixed
        cost_per_click
        max_commission_budget
        invitation
        accepted_at
        declined_at
        declined_reason
        declined_reason_text
        created_at
        updated_at
        expires_at
        status
    }
}`;

export const CampaignsSchema = ` {
    response {
        uuid
        name
        status{
            name
        }
        utm_campaign
        utm_medium
        description
        recommendations_description
        promotion_material_description
        product_description
        hidden
        allow_deep_linking
        cost_per_action_percent
        cost_per_action_fixed
        cost_per_click
        commission_type
        max_commission_budget
        url
        min_followers
        max_followers
        participants
        starting_at
        ending_at
        published_at
        created_at
        updated_at
        image_url
        registration_open
        country {
            name
            flag
            iso_3166_2
        }
        business {
            uuid
            company
            display_name
            website
            description
            pixel_approved_at
            user{
                first_name
            }
        }
        category { 
            id
            display_name
            icon
        }
        agency {
            uuid
            name
            user {
                first_name
            }
        }
    }
    lastPage
    currentPage
    total
    perPage
}`;

export const CampaignsSummarySchema = ` {
    response {
        uuid
        name
        status{
            name
        }
        hidden
        cost_per_action_percent
        cost_per_action_fixed
        cost_per_click
        max_commission_budget
        min_followers
        ending_at
        created_at
        published_at
        image_url
        country {
            name
            flag
            iso_3166_2
        }
        business {
            uuid
            display_name
        }
        influencers {
            uuid
        }
    }
    lastPage
    currentPage
    total
    perPage
}`;

export const CreateCampaignSchema = `{
    uuid
    agency{
        uuid
        name
    }
    business{
        uuid
        display_name
        company
    }

}`;

export const CampaignInfluencerSchema = `{
    uuid
    note
    cost_per_action_percent
    cost_per_action_fixed
    cost_per_click
    max_commission_budget
    invitation
    accepted_at
    declined_at
    declined_reason
    declined_reason_text
    created_at
    updated_at
    expires_at
    campaign{
        uuid
        name
    }
    influencer ${InfluencerSchema}
}`;

export const CampaignInfluencersSchema = `{
response ${CampaignInfluencerSchema}
  lastPage
  currentPage
  total
  perPage
}`;

export const CreateCampaignInfluencerSchema = `{
    uuid
    note
    cost_per_action_percent
    cost_per_action_fixed
    cost_per_click
    max_commission_budget
    invitation
    accepted_at
    declined_at
    declined_reason
    declined_reason_text
    created_at
    updated_at
    expires_at
    campaign{
        uuid
        name
    }
    influencer ${InfluencerSchema}
}`;

export const getCampaignsForInfluencerSchema = `{
    lastPage
    currentPage
    total
    perPage
    response{
        uuid
        declined_at
        declined_reason
        declined_reason_text
        accepted_at
        expires_at
        invitation
        campaign {
            uuid
            published_at
            image_url
            active
            name
            status{
                name
                order
            }
            business{
                display_name
            }
            cost_per_action_percent
            cost_per_action_fixed
            cost_per_click
            max_commission_budget
            participants
            starting_at
            ending_at
            min_followers
            max_followers
        }
    }
}`;

export const BulkInviteCampaignSchema = `{
    invitations {
        uuid
        note
        cost_per_action_percent
        cost_per_action_fixed
        cost_per_click
        max_commission_budget
        invitation
        influencer {
            uuid
        }
    }
}`;

export const CampaignInfluencersGroupSchema = `{
    accepted ${CreateCampaignInfluencerSchema}
    applied ${CreateCampaignInfluencerSchema}
    invited ${CreateCampaignInfluencerSchema}
    rejected ${CreateCampaignInfluencerSchema}
}`;

export const CampaignBudgetOverviewSchema = `{
    data {
        influencer_id
        first_name
        last_name
        cost_per_click
        cost_per_click_realized
        max_commission_budget_amount
        used_budget_amount
        remaining_budget_amount
        transaction_clicks
        unique_clicks
        clicks
    }
    limit
    total
    currentPage
    lastPage
}`;

export const InfluencerRequestsSchema = `{
    uuid
    note
    cost_per_action_percent
    cost_per_action_fixed
    cost_per_click
    max_commission_budget
    invitation
    accepted_at
    declined_at
    declined_reason
    declined_reason_text
    created_at
    updated_at
    expires_at
    campaign{
        uuid
        name
    }
    influencer ${InfluencerSchema}
}`;

export const BusinessInfluencerRequestsSchema = `{
    uuid
    note
    cost_per_action_percent
    cost_per_action_fixed
    cost_per_click
    max_commission_budget
    invitation
    accepted_at
    declined_at
    declined_reason
    declined_reason_text
    created_at
    updated_at
    expires_at
    is_favorite
    campaign{
        uuid
        name
    }
    influencer ${InfluencerSchema}
}`;

export const InfluencerRequestsCountsSchema = `{
    activeInfluencersCount
    pendingInvitationsCount
    pendingApplicationsCount
    endedInfluencersCount
    declinedCount
}`

export const InfluencerRequestsPaginatedSchema = `{
    data ${BusinessInfluencerRequestsSchema}
    lastPage
    currentPage
    total
    limit
    activeInfluencersCount
    pendingInvitationsCount
    pendingApplicationsCount
    endedInfluencersCount
    declinedCount
}`

export const ModeratorInfluencerRequestsPaginatedSchema = `{
    data ${InfluencerRequestsSchema}
    lastPage
    currentPage
    total
    limit
    activeInfluencersCount
    pendingInvitationsCount
    pendingApplicationsCount
    endedInfluencersCount
    declinedCount
}`

export const CampaignRequestSchema = `{
    uuid
    note
    cost_per_action_percent
    cost_per_action_fixed
    cost_per_click
    max_commission_budget
    invitation
    accepted_at
    declined_at
    declined_reason
    declined_reason_text
    is_retracted
    status
    created_at
    updated_at
    expires_at

    campaign ${CampaignSchema}
}`;

export const RetractCampaignRequestSchema = `{
    uuid
    note
    cost_per_action_percent
    cost_per_action_fixed
    cost_per_click
    max_commission_budget
    invitation
    accepted_at
    declined_at
    declined_reason
    declined_reason_text
    is_retracted
    status
    created_at
    updated_at
    expires_at
}`;

export const CampaignRequestCountsSchema = `{
    activeCampaignsCount
    pendingCampaignsCount
    pendingInvitationsCount
    pendingApplicationsCount
    endedCampaignsCount
    declinedCount
}`;

export const CampaignRequestsPaginatedSchema = `{
    data ${CampaignRequestSchema}
    lastPage
    currentPage
    total
    limit
}`;