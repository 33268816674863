<template>
  <v-stepper v-model="step" class="elevation-0">
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-item-group mandatory v-model="selectedProductCard" @change="selectProduct(products[$event])">
          <v-container fluid grid-list-lg class="p-0 mt-3" v-if="!isLoading">
            <v-layout row wrap>
              <v-flex sm12 xs12 v-for="product in products" :key="product.id">
                <v-item v-slot="{ active, toggle }">
                  <v-card outlined :class="active ? 'v-card-step-1 secondary' : 'v-card-step-1'" @click="toggle">
                    <v-card-title class="justify-center">
                      {{ product.display_name }}
                      <span v-if="product.name === 'growth'" class="ml-2 growth-rocket">🚀</span>
                    </v-card-title>
                    <v-card-subtitle class="text-center">
                      <em class="text-caption">invoiced {{ product.selectedPrice.payment_interval }}</em><br>
                      <strong>{{ product.selectedPrice.monthly_amount | local_numbers}} kr.</strong> per month
                    </v-card-subtitle>
                    <v-card-actions class="justify-center">
                      <v-chip-group mandatory column active-class="primary--text">
                        <v-chip small pill v-for="price in product.prices" :key="price.id" @click="setSelectPriceOnProduct(product, price);">
                          {{ price.payment_interval }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-actions>
                    <v-card-text>
                      <strong>Included in this plan…</strong>
                      <ul>
                        <li v-if="product.settings.service_fee_percent">
                          {{ product.settings.service_fee_percent }}% Commission fee
                        </li>
                        <!-- <li v-if="product.name === 'growth' || product.name === 'pro'">
                          Dedikeret kundesucces manager
                        </li> -->
                        <!-- <li v-if="product.settings.commission_approval_days">
                          Kommissionens godkendelse {{ product.settings.commission_approval_days }} dage.
                        </li> -->
                        <!-- <li v-if="product.settings.allowed_active_campaigns">
                          Op til {{ product.settings.allowed_active_campaigns }} aktive kampagner.
                        </li> -->
                        <li v-if="product.settings.allowed_influencers_per_campaign">
                          {{ product.settings.allowed_influencers_per_campaign * product.settings.allowed_active_campaigns }} Influencers
                        </li>
                        <li>
                          <a href="https://www.makeinfluence.com/da/virksomhed/priser" target="_blank">Read more here</a>
                        </li>
                      </ul>
                    </v-card-text>
                    <v-card-actions class="justify-center" v-if="active">
                      <v-btn class="mb-3" color="success" @click="nextStep">Yes, please!</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-item>
              </v-flex>
            </v-layout>
          </v-container>
        </v-item-group>
        <v-container fluid grid-list-lg class="p-0 mt-3" v-if="isLoading">
          <v-layout row wrap>
              <v-flex sm12 xs12 v-for="(item, k) in 1" :key="`sl-${k}`">
                <v-skeleton-loader type="image" class="subscription-dialog-loader">
                </v-skeleton-loader>
              </v-flex>
          </v-layout>
        </v-container>
        <v-container class="p-0 pb-1 mt-4 mb-2" v-if="!isLoading">
          <v-layout row wrap>
            <v-flex xs12 class="text-center">
              <div class="caption mb-2">&mdash; eller &mdash;</div> 
              <v-btn
                outlined
                target="_blank"
                href="https://www.makeinfluence.com/en/business/calendar-book-e-commerce"
              >
                Book meeting
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-container fluid grid-list-lg class="p-0 mt-3" v-if="!isLoading">
          <v-layout row wrap>
            <v-flex xs12>
              <v-card outlined class="v-card-step-2">
                <v-card-title>
                  Fill in payment details
                </v-card-title>

                <v-card-subtitle>
                  You won't be charged until your free trial ends.
                </v-card-subtitle>

                <v-card-text>
                  <div class="p-2 mt-2" style="border: 1px solid #e3e3e3">
                    <PaymentCard
                      class="stripe-card"
                      ref="stripeCardElement"
                      v-if="settings.stripe_key"
                      @cardChange="cardChange"
                      :stripe="stripe"
                    >
                    </PaymentCard>
                  </div>
                </v-card-text>

                <v-card-text class="d-flex align-center">
                  <v-checkbox v-model="acceptedTerms" />

                  You must accept our

                  <a href="https://www.makeinfluence.com/en/business/terms-and-conditions" target="_blank" rel="noopener" class="ml-1">
                    Terms and conditions
                  </a>
                </v-card-text>
              </v-card>
            </v-flex>
            <!-- <v-flex sm6 xs12>
              <v-card outlined class="v-card-step-2" v-if="selectedProduct && selectedPrice">
                <v-card-title>
                  Overview
                </v-card-title>
                <v-card-text>
                  <v-layout row wrap>

                    <v-flex xs12 class="mt-4">
                      <v-layout class="pb-2">
                        <v-flex xs6>
                          {{ selectedProduct.display_name }} {{ selectedPrice.payment_interval }}
                        </v-flex>
                        <v-flex xs6 class="text-right">
                          <span>{{ subscriptionTotal | local_numbers }}</span> kr.
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12 v-if="coupon !== null">
                      <v-divider></v-divider>
                      <v-layout class="pb-2">
                        <v-flex xs6>
                          Discount
                          <span v-if="coupon.duration.toLowerCase() === 'forever'">
                            (forever)
                          </span>
                          <span v-else-if="coupon.duration.toLowerCase() === 'once'">
                            (first period)
                          </span>
                          <span v-else>
                            (Applicable {{ coupon.duration_in_months }} months)
                          </span>
                        </v-flex>
                        <v-flex xs6 class="text-right">
                          <span>-{{ discountTotal | local_numbers }}</span> kr.
                        </v-flex>
                      </v-layout>
                    </v-flex>

                    <v-flex xs12>
                      <v-divider class="mb-1"></v-divider>
                      <v-divider></v-divider>

                      <v-layout class="pb-2">
                        <v-flex xs6>
                          Subtotal
                        </v-flex>
                        <v-flex xs6 class="text-right">
                          <span>{{ subTotal | local_numbers }}</span> kr.
                        </v-flex>
                      </v-layout>

                      <v-divider></v-divider>
                    </v-flex>

                    <v-flex xs12>
                      <v-layout class="pt-2 pb-2">
                        <v-flex xs6>
                          VAT (25%)
                        </v-flex>
                        <v-flex xs6 class="text-right">
                          <span>{{ vatPrice | local_numbers }}</span> kr.
                        </v-flex>
                      </v-layout>

                      <v-divider></v-divider>
                    </v-flex>

                    <v-flex xs12>
                      <v-layout>
                        <v-flex xs6 class="font-weight-bold">
                          Total
                        </v-flex>
                        <v-flex xs6 class="text-right">
                          <span class="font-weight-bold headline">
                            {{ fullPrice | local_numbers }}
                          </span>
                          kr.
                        </v-flex>
                      </v-layout>

                      <v-divider></v-divider>
                      <v-divider></v-divider>
                    </v-flex>

                    <v-flex xs12>
                      <v-btn text x-small
                        class="coupon-button float-right"
                        v-if="!hasCoupon"
                        @click="hasCoupon = true">
                        Add discount code
                      </v-btn>

                      <v-text-field
                        v-if="hasCoupon"
                        v-model="selectedCoupon"
                        label="Rabatkode"
                        class="mt-3"
                        outlined
                        dense
                        :loading="loadingCoupon"
                        :append-icon="
                          selectedCoupon && !loadingCoupon
                            ? coupon
                              ? 'fal fa-check'
                              : 'fal fa-times'
                            : null
                        "
                        :color="
                          selectedCoupon && !loadingCoupon
                            ? coupon
                              ? 'success'
                              : 'error'
                            : 'primary'
                        "
                      ></v-text-field>
                    </v-flex>

                  </v-layout>
                </v-card-text>
              </v-card>
            </v-flex> -->
            <v-flex xs12 class="text-center">
              <v-btn
                text
                class="mr-3"
                @click="prevStep"
              >
                Back
              </v-btn>
              <v-btn
                color="success"
                @click="createPaymentData()"
                :disabled="!card || !acceptedTerms"
                :loading="changeCardLoading"
              >
                Subscribe
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<style>
  .subscription-dialog-loader .v-skeleton-loader__image {
    height: 380px;
  }
</style>
<style scoped>
  .coupon-button {
    color: var(--v-secondary-darken3);
  }
  .v-card-step-1 {
    min-height: 380px;
  }
  .v-card-step-2 {
    min-height: 240px;
  }
</style>
<script>
import { mapState, mapGetters, mapActions } from "vuex";
import local_numbers from "@/helpers/filters/local_numbers";
import PaymentCard from "@/components/common/forms/PaymentCard.vue";
import _ from 'lodash';

export default {
  components: { PaymentCard },
  filters: {
    local_numbers
  },
  data: () => ({
    step: 1,
    isLoading: true,
    selectedProductCard: 1,
    selectedProduct: null,
    selectedPrice: null,
    selectedCoupon: null,
    changeCardLoading: false,
    stripePaymentIntentClientSecret: null,
    cardName: null,
    stripe: null,
    card: null,
    hasCoupon: false,
    coupon: null,
    loadingCoupon: false,
    debugOut: {},
    acceptedTerms: false,
  }),
  computed: {
    ...mapState("settings", ["settings"]),
    ...mapState("core/auth", ["user"]),
    ...mapGetters("subscriptions", ["getSubscriptionProducts"]),
    products() {
        let products = [];

        for(let i = 0; i < this.getSubscriptionProducts.length; i++){
          let _product = this.getSubscriptionProducts[i];
          if (_product.prices.length > 0) {
            _product.selectedPrice = _product.prices[0];
            products.push(_product);
          }
        }

        return products;
    },
    subscriptionTotal() {
      return this.selectedPrice.unit_amount;
    },
    discountTotal() {
      let total = 0;

      if (this.coupon) {
        if (this.coupon.amount_off) {
          total += this.coupon.amount_off;
        }

        if (this.coupon.percent_off) {
          total += (this.coupon.percent_off / 100) * this.subscriptionTotal;
        }
      }

      return total;
    },
    subTotal() {
      return this.subscriptionTotal - this.discountTotal;
    },
    vatPrice() {
      return this.subTotal * 0.25;
    },
    fullPrice() {
      return this.subTotal + this.vatPrice;
    }
  },
  watch: {
    selectedCoupon: _.debounce(function() {
      this.checkCouponValidation();
    }, 500)
  },
  methods: {
    ...mapActions("subscriptions",
    [
      "fetchSubscriptionProducts",
      "createSubscription",
      "setShowSubscriptionDialog"
    ]),
    ...mapActions("core", ["paymentCards", "paymentIntent", "getDiscount"]),
    setSelectPriceOnProduct(product, price) {
      product.selectedPrice = price;
      this.selectedPrice = price;

      /**
       * Force update of the component, for some reason only this will work.
       * Cloning objects, unique hashing, creating extra keys and forcing them
       * to update, will all refresh the object, but still the component will not update.
       * If you know another way, be my guest :)
       */
      this.$forceUpdate();
    },
    selectProduct(product) {
      this.selectedProduct = product;
      this.selectedPrice = product.selectedPrice;
    },
    nextStep() {
      this.step++;
    },
    prevStep() {
      this.step--;
    },
    checkCouponValidation() {
      this.coupon = null;
      // Don't load if coupon is empty
      if (this.selectedCoupon.length < 1) {
        return false;
      }
      this.loadingCoupon = true;
      const params = {
        coupon: this.selectedCoupon
      };
      this.getDiscount(params).then(
        response => {
          this.loadingCoupon = false;
          this.coupon = response;
        },
        () => {
          this.loadingCoupon = false;
        }
      );
    },
    cardChange(event) {
      this.card = event;
    },
    createPaymentData() {
      this.changeCardLoading = true;
      const options = {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.cardName
          },
        }
      };
      this.stripe
        .confirmCardSetup(this.stripePaymentIntentClientSecret, options)
        .then(result => {
          if (result.error) {
            this.handleError(result.error);
          } else {
            let params = {
              default_payment_method: result.setupIntent.payment_method,
              product: this.selectedProduct.name,
              price: this.selectedPrice.payment_interval,
            };
            if (this.selectedCoupon) {
              params.coupon = this.selectedCoupon;
            }
            this.startSubscription(params);
          }
        });
    },
    startSubscription(params) {
      this.createSubscription(params).then(
        () => {
          this.setSnackSuccess("Success");
          this.setShowSubscriptionDialog(false);
          this.changeCardLoading = false;
        },
        error => {
          this.setSnackError("Something went wrong");
          console.log(error);
          this.changeCardLoading = false;
        },
      );
    },
    // eslint-disable-next-line no-unused-vars
    handleError(error) {
      this.setSnackError("Something went wrong");
      this.changeCardLoading = false;
    },
  },
  created() {
    this.cardName = this.user.first_name + " " + this.user.last_name;
    this.stripe = window.Stripe(this.settings.stripe_key);

    this.paymentIntent().then(
      response => {
        this.stripePaymentIntentClientSecret = response.secret;
      },
      error => {
        this.handleError(error);
        this.setSnackError("Error");
      }
    );

    this.fetchSubscriptionProducts().then(() => {
      console.log(this.products)
      this.isLoading = false;
      this.selectedProduct = this.products[1];
      this.selectedPrice = this.products[1].prices[0];
    });
  },
}
</script>
