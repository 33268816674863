export const ChannelsSchema = `{
    uuid
    name
    display_name
    description
    icon
    login_link
    created_at
    updated_at
}`;

export const InfluencerChannelsSchema = `{
  uuid
  username
  description
  website
  profile_image_url
  followers
  reach
  impressions
  media_count
  total_likes
  reference
  api_key
  active
  analytics_updated_at
  api_updated_at
  api_failed_at
  approved_at
  declined_at
  created_at
  updated_at
  channel{
    uuid
    name
    display_name
    description
    icon
    login_link
    created_at
    updated_at
  }
  influencer{
    uuid
    birthday
    gender
  }
  categories{
    id
    name
    display_name
    description
    icon
    created_at
    updated_at
  }
  analytics{
    id
    date
    followers
  }
}`;
export const ChannelCategoriesSchema = `{
  id
  name
  display_name
  description
  icon
  created_at
  updated_at
}`;
