export const SubscriptionSchema = `{
    uuid
    ends_at
    next_period_at
    is_active
    is_trialing
    is_canceled
    is_churned
    is_shopify
    has_scheduled_change
    product {
      name
      display_name
      price {
        name
        currency
        unit_amount
        payment_interval
      }
    }
    addons {
      name
      quantity
      price {
        name
        unit_amount
      }
    }
    permissions
    settings
}`

export const SubscriptionProductSchema = `{
    id
    name
    display_name
    settings
    prices {
        id
        currency
        payment_interval
        unit_amount
        monthly_amount
        active
    }
}`
