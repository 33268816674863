export default function(channelName) {
  switch (channelName) {
    case "facebook":
      return "indigo darken-1";

    case "instagram":
      return "primary";

    case "tiktok":
      return "grey darken-4";
  }
}
