/* eslint-disable no-unused-vars */
import { authHelper } from "@/helpers/classes/auth.js";
import {
  InvoiceAccountSchema,
  InvoiceSchema,
  ForceInvoiceTransactionResponse
} from "@/helpers/schemas/core/invoice.js";
import {
  ChannelCategoriesSchema,
  InfluencerChannelCitiesSchema,
  InfluencerChannelCitySchema,
  InfluencerChannelAgeGendersSchema
} from "@/helpers/schemas/core/channels.js";
import {
  UpdateUserSchema,
  UploadAvatarSchema,
  AgencySchema,
  UpdateUserSettingSchema,
  InfluencerSchema,
  InfluencersSchema,
  InfluencersAutocompleteSchema,
  BusinessSchema,
  BusinesessSchema,
  updatePayoutAccountSchema
} from "@/helpers/schemas/core/users.js";
import {
  SubscriptionSchema,
  updateSelfSubscriptionSchema,
  UnfinishedSubscriptionSchema,
  SubscriptionUpdateSchema,
  DiscountSchema,
  PaymentIntentSchema,
  PaymentCardsSchema,
  StripePlansSchema
} from "@/helpers/schemas/core/subscriptions.js";
import {
  StatsSchema,
  DashboardBusinessSchema,
  DashboardInfluencerSchema,
  InfluencerStatsSchema,
  ComissionStatusSchema,
  InfluencerCommissionStatuses,
  ActiveUsersStatsSchema,
  InstagramReportSummerized,
  InstagramReportInfluencers,
  LegacyStats,
  LegacyStatsByInfluencer
} from "@/helpers/schemas/core/stats.js";

import {
  TransactionsPaginated,
  TransactionReportPaginated,
  TransactionSchema
} from "@/helpers/schemas/core/transactions.js";

import {
  ClicksPaginated
} from "@/helpers/schemas/core/clicks.js"

import {
  CreateSaleSchema,
  SalesSchema,
  ModeratorSalesSchema
} from "@/helpers/schemas/core/sales.js";
import { TrackingLinkShema } from "@/helpers/schemas/core/influencers.js";

import { ConversionLogsPaginatedSchema } from "@/helpers/schemas/core/logs.js";
import { CampaignBudgetOverviewSchema } from "../../../helpers/schemas/core/campaigns";

import { CountriesSchema } from "../../../helpers/schemas/core/countries";

// Methods. NEVER update state data from an action/method
// Update state data with mutations
const auth = authHelper();

const actions = {
  getCountries({ commit }, params) {
    const data = {
      params: params,
      result: CountriesSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("countries", data).then(
        response => {
          resolve(response.data.countries);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  // invoice actions
  getInvoices({ commit }, params) {
    const data = {
      params: params,
      result: InvoiceSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("invoices", data).then(
        response => {
          resolve(response.data.invoices);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getInvoiceAccount({ commit }) {
    const data = {
      params: null,
      result: InvoiceAccountSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("invoiceAccounts", data).then(
        response => {
          resolve(response.data.invoiceAccounts);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateInvoiceAccount({ commit }, params) {
    const data = {
      params: params,
      result: InvoiceAccountSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateInvoiceAccount", data).then(
        response => {
          resolve(response.data.updateInvoiceAccount);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  // user actions
  updateUserData({ dispatch }, userData) {
    const data = {
      params: userData,
      result: UpdateUserSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateUserData", data).then(
        response => {
          resolve(response.data.updateUserData);
        },
        error => {
          reject(error);
        }
      );
    });
  },

  uploadUserAvatar({ dispatch }, params) {
    const data = {
      params: params,
      result: UploadAvatarSchema,
      type: "UserAvatar"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.uploadAvatar("uploadUserAvatar", data).then(
        response => {
          resolve(response.data.uploadUserAvatar);
        },
        error => {
          reject(error);
        }
      );
    });
  },

  deleteUserAvatar({ dispatch }) {
    const data = {
      params: null,
      result: UploadAvatarSchema,
      type: "UserAvatar"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("deleteUserAvatar", data).then(
        response => {
          resolve(response.data.deleteUserAvatar);
        },
        error => {
          reject(error);
        }
      );
    });
  },

  importInfluencerAvatar({ dispatch }, params) {
    const data = {
      params: params,
      result: UploadAvatarSchema,
      type: "InfluencerAvatar"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("importInfluencerInstagramAvatar", data).then(
        response => {
          resolve(response.data.importInfluencerInstagramAvatar);
        },
        error => {
          reject(error);
        }
      );
    });
  },

  updateUserSetting({ dispatch }, settings) {
    const data = {
      params: settings,
      result: UpdateUserSettingSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateUserSetting", data).then(
        response => {
          resolve(response.data.updateUserSetting);
        },
        error => {
          reject(error);
        }
      );
    });
  },

  updateUserPassword({ dispatch }, settings) {
    const data = {
      params: settings,
      result: UpdateUserSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateUserPassword", data).then(
        response => {
          resolve(response.data.updateUserPassword);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  // stats actions
  getStats({ commit }, params) {
    const data = {
      params: params,
      result: StatsSchema,
      type: "Stats"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("stats", data).then(
        response => {
          resolve(response.data.stats.data);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getDashboardBusinessStats({ commit }, params) {
    const data = {
      params: params,
      result: DashboardBusinessSchema,
      type: "Stats"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("stats", data).then(
        response => {
          resolve(response.data.stats);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getDashboardInfluencerStats({ commit }, params) {
    const data = {
      params: params,
      result: DashboardInfluencerSchema,
      type: "Stats"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("stats", data).then(
        response => {
          resolve(response.data.stats);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getInfluencerStats({ commit }, params) {
    const data = {
      params: params,
      result: InfluencerStatsSchema,
      type: "InfluencerChannelStats"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("influencerChannelStats", data).then(
        response => {
          resolve(response.data.influencerChannelStats);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getComissionStatus({ commit }, params) {
    const data = {
      params: params,
      result: ComissionStatusSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("commissionStatus", data).then(
        response => {
          resolve(response.data.commissionStatus);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  activeUserQuery({ commit }) {
    const data = {
      params: null,
      result: ActiveUsersStatsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("activeUserQuery", data).then(
        response => {
          resolve(response.data.activeUserQuery);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  // subscription actions
  approveSubscription({ commit }, params) {
    const data = {
      params: params,
      result: SubscriptionSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("approveSubscription", data).then(
        response => {
          resolve(response.data.approveSubscription);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createSubsctiption({ commit }, params) {
    const data = {
      params: params,
      result: {
        StripeSubscriptions: SubscriptionSchema,
        StripeSubscriptionResponse: UnfinishedSubscriptionSchema
      },
      type: ["StripeSubscriptions", "StripeSubscriptionResponse"]
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createSubscription", data).then(
        response => {
          resolve(response.data.createSubscription);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateSubscription({ commit }, params) {
    const data = {
      params: params,
      result: SubscriptionUpdateSchema,
      type: "StripeSubscriptionsUpdateResponse"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateSubscription", data).then(
        response => {
          resolve(response.data.updateSubscription);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updateSelfSubscription({ commit }, params) {
    const data = {
      params: params,
      result: updateSelfSubscriptionSchema,
      type: "StripeSubscriptionsResponseData"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updateSelfSubscription", data).then(
        response => {
          resolve(response.data.updateSelfSubscription);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  cancelSubscription({ commit }, params) {
    const data = {
      params: params,
      result: SubscriptionSchema,
      type: "StripeSubscriptions"
    };

    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("cancelSubscription", data).then(
        response => {
          resolve(response.data.cancelSubscription);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getDiscount({ commit }, params) {
    const data = {
      params: params,
      result: DiscountSchema,
      type: "Discount"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("getDiscount", data).then(
        response => {
          resolve(response.data.getDiscount);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  paymentIntent({ commit }) {
    const data = {
      params: null,
      result: PaymentIntentSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("paymentIntent", data).then(
        response => {
          resolve(response.data.paymentIntent);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  paymentCards({ commit }, params) {
    const data = {
      params: params,
      result: PaymentCardsSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("paymentCards", data).then(
        response => {
          resolve(response.data.paymentCards);
        },
        error => {
          reject(error);
        }
      );
    });
  },

  // influencer actions
  getInfluencers({ commit }, params) {
    const data = {
      params: params,
      result: InfluencersSchema,
      type: "InfluencersPaginated"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("influencers", data).then(
        response => {
          resolve(response.data.influencers);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getInfluencersAutocomplete({ commit }, params) {
    const data = {
      params: params,
      result: InfluencersAutocompleteSchema,
      type: "InfluencersPaginated"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("influencers", data).then(
        response => {
          resolve(response.data.influencers);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getInfluencer({ commit }, params) {
    const data = {
      params: params,
      result: InfluencerSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("influencer", data).then(
        response => {
          resolve(response.data.influencer);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  deleteInfluencerAccount({ dispatch }, params) {
    const data = {
      params: params,
      result: InfluencerSchema,
      type: "Influencer"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("deleteInfluencer", data).then(
        response => {
          resolve(response.data.deleteInfluencer);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getChannelCategories({ commit }, params) {
    const data = {
      params: params,
      result: ChannelCategoriesSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("channelCategories", data).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getInfluencerChannelCities({ commit }, params) {
    const data = {
      params: params,
      result: InfluencerChannelCitiesSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("influencerChannelCities", data).then(
        ({ data }) => {
          resolve(data.influencerChannelCities);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getInfluencerChannelCity({ commit }, params) {
    const data = {
      params: params,
      result: InfluencerChannelCitySchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("influencerChannelCity", data).then(
        ({ data }) => {
          resolve(data.influencerChannelCity);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getInfluencerAgeGenders({ commit }, params) {
    const data = {
      params: params,
      result: InfluencerChannelAgeGendersSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("influencerChannelAgeGenders", data).then(
        ({ data }) => {
          resolve(data.influencerChannelAgeGenders);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  // business module
  getBusinesses({ commit }, params) {
    const data = {
      params: params,
      result: BusinesessSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("businesses", data).then(
        response => {
          resolve(response.data.businesses);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getBusiness({ commit }, params) {
    const data = {
      params: params,
      result: BusinessSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("business", data).then(
        response => {
          let business = {};

          if (response.data.business) {
            business = response.data.business;
            business.subscriptionStatus = "Inactive";

            if (business.user.active_subscription
                && business.user.active_subscription.is_churned) {
              business.subscriptionStatus = "Churned";
            }
    
            if (business.user.active_subscription
                && business.user.active_subscription.is_active) {
              business.subscriptionStatus = "Active";
            }

            if (business.user.active_subscription
              && business.user.active_subscription.is_trialing) {
              business.subscriptionStatus = "Trialing";
            }
    
            if (business.user.active_subscription
                && business.user.active_subscription.is_canceled) {
              business.subscriptionStatus = "Canceled";
            }
          }

          resolve(business);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getAgencies({ commit }, params) {
    const data = {
      params: params,
      result: AgencySchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("agencies", data).then(
        response => {
          resolve(response.data.agencies);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getSales({ rootGetters }, params) {

    const data = {
      params: params,
      result: rootGetters["core/auth/isModerator"]
        ? ModeratorSalesSchema
        : SalesSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("sales", data).then(
        response => {
          resolve(response.data.sales);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createSale({ commit }, params) {
    const data = {
      params: params,
      result: CreateSaleSchema,
      type: "Sale"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createSale", data).then(
        response => {
          resolve(response.data.createSale);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getStripePlans({ commit }, params) {
    const data = {
      params: params,
      result: StripePlansSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("getPlans", data).then(
        response => {
          resolve(response.data.getPlans);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  createTrackingLink({ commit }, params) {
    const data = {
      params: params,
      result: TrackingLinkShema,
      type: "TrackingLink"
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createTrackingLink", data).then(
        response => {
          resolve(response.data.createTrackingLink);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getInfluencerCommissionStatuses({ commit }, params) {
    const data = {
      params: params,
      result: InfluencerCommissionStatuses
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("influencerCommissionStatuses", data).then(
        response => {
          resolve(response.data.influencerCommissionStatuses);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getLegacyStats() {
    const data = {
      result: LegacyStats
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("legacyStats", data).then(
        response => {
          resolve(response.data.legacyStats);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  getLegacyStatsByInfluencer({ commit }, params) {
    const data = {
      params: params,
      result: LegacyStatsByInfluencer
    };
    console.log(data);
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("legacyStatsByInfluencer", data).then(
        response => {
          resolve(response.data.legacyStatsByInfluencer);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  transferCommission({ commit }, params) {
    let result = `{
        success
      }`;

    const data = {
      params: params,
      result: result
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("transferInfluencerCommission", data, true).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  manualTransferCommission({ commit }, params) {
    let result = `{
        success
      }`;

    const data = {
      params: params,
      result: result
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("manualTransferInfluencerCommission", data, true).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  makeLegacyTransfer({ commit }, params) {
    let result = `{
      influencer_id
      legacy_paid
      paid_installments
    }`;

    const data = {
      params: params,
      result: result
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("makeLegacyTransfer", data, true).then(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  },
  updatePayoutAccount({ dispatch }, params) {
    const data = {
      params: params,
      result: updatePayoutAccountSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("updatePayoutAccount", data).then(
          response => {
            resolve(response.data.updateUserPassword);
          },
          error => {
            reject(error);
          }
      );
    });
  },
  // invoice actions
  getTransactions({ commit }, params) {
    const data = {
      params: params,
      result: TransactionsPaginated
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("transactions", data).then(
          response => {
            resolve(response.data.transactions);
          },
          error => {
            reject(error);
          }
      );
    });
  },
  // transactions report
  getTransactionsReport({ commit }, params) {
    const data = {
      params: params,
      result: TransactionReportPaginated
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("transactions", data).then(
          response => {
            resolve(response.data.transactions);
          },
          error => {
            reject(error);
          }
      );
    });
  },
  createTransactionRefund({ commit }, params) {
    const data = {
      params,
      result: TransactionSchema,
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("refundTransaction", data, true).then(
          response => {
            resolve(response.data.refundTransaction);
          },
          error => {
            reject(error);
          }
      );
    });
  },
  createTransaction({ commit }, params) {
    const data = {
      params,
      result: TransactionSchema,
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("createTransaction", data, true).then(
          response => {
            resolve(response.data.createTransaction);
          },
          error => {
            reject(error);
          }
      );
    });
  },
  // transactions report
  getConversionLogs({ commit }, params) {
    const data = {
      params: params,
      result: ConversionLogsPaginatedSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("conversionLogs", data).then(
          response => {
            resolve(response.data.conversionLogs);
          },
          error => {
            reject(error);
          }
      );
    });
  },
  // clicks report
  getClicks({ commit }, params) {
    const data = {
      params,
      result: ClicksPaginated
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("clicks", data).then(
        response => {
          resolve(response.data.clicks);
        },
        error => {
          reject(error);
        }
      );
    })
  },
  forceInvoiceTransactions({ commit }, params) {
    const data = {
      params,
      result: ForceInvoiceTransactionResponse
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.mutate("forceInvoiceTransactions", data, true).then(
          response => {
            resolve(response.data.forceInvoiceTransactions);
          },
          error => {
            reject(error);
          }
      );
    })
  },
  // clicks report
  getCampaignBudgetOverview({ commit }, params) {
    const data = {
      params,
      result: CampaignBudgetOverviewSchema
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("campaignBudgetOverview", data).then(
          response => {
            resolve(response.data.campaignBudgetOverview);
          },
          error => {
            reject(error);
          }
      );
    })
  },

  // Instagram report - summerized
  getInstagramReportSummerized({ commit }, params) {
    const data = {
      params,
      result: InstagramReportSummerized
    };

    return new Promise((resolve, reject) => {
      this._vm.$gql.query("InstagramReportSummerized", data).then(
          response => {

            resolve(response.data.InstagramReportSummerized);
          },
          error => {
            reject(error);
          }
      );
    })
  },

  // Instagram report - Influencers
  getInstagramReportInfluencers({ commit }, params) {
    const data = {
      params,
      result: InstagramReportInfluencers
    };
    return new Promise((resolve, reject) => {
      this._vm.$gql.query("InstagramReportInfluencers", data).then(
          response => {
            resolve(response.data.InstagramReportInfluencers);
          },
          error => {
            reject(error);
          }
      );
    })
  }
};

export default actions;
