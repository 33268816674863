<template>
  <div>
    <v-card :class="[{ 'elevation-0 bordered': bordered }]">
      <div class="p-3 text-center">
        <v-avatar :size="80">
          <img :src="influencerChannel.profile_image_url" />
        </v-avatar>
      </div>
      <v-card-text>
        <v-list two-line class="pa-0">
          <v-list-item>
            <v-list-item-action>
              <v-icon>{{
                influencerChannel.channel.icon
              }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ influencerChannel.username }}
              </v-list-item-title>
              <v-list-item-subtitle>{{
                influencerChannel.channel.display_name
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon>fal fa-users</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ influencerChannel.followers | local_numbers }}
              </v-list-item-title>
              <v-list-item-subtitle>Followers</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon>fal fa-camera-alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ influencerChannel.media_count | local_numbers }}
              </v-list-item-title>
              <v-list-item-subtitle>Posts</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action>
              <v-icon>fal fa-link</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <span
                  v-if="
                    influencerChannel.active && influencerChannel.api_failed_at
                  "
                  class="error--text"
                >
                  Lost connection
                </span>

                <span
                  v-else-if="
                    influencerChannel.active && !influencerChannel.approved_at
                  "
                  class="amber--text"
                >
                  Pending approval
                </span>

                <span v-else-if="!influencerChannel.active">
                  Not connected
                </span>
              </v-list-item-title>
              <v-list-item-subtitle>Status</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="influencerChannel.declined_at"
          color="primary"
          block
          disabled
        >
          Previously rejected media, try again later
        </v-btn>
        <v-btn
          v-else
          color="primary"
          block
          :disabled="
            (influencerChannel.active && !influencerChannel.approved_at) ||
              channelActivated
          "
          @click="showConnectDialog = true"
        >
          {{ saveButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="showConnectDialog" max-width="500">
      <v-card>
        <v-card-title>
          <div>
            <div class="headline">
              Connect <b>{{ influencerChannel.username }}</b>
            </div>
            <div class="subtitle-1 light-grey">
              Choose the categories, that best describe the content on
              {{ influencerChannel.username }}
            </div>
          </div>
        </v-card-title>

        <v-card-text>
          <v-list two-line class="pa-0">
            <v-list-item
              v-for="category in categories"
              :key="category.id"
              @click="toggleCategory(category)"
            >
              <v-list-item-action>
                <v-icon>{{ category.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  {{ category.display_name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-icon v-if="hasCategory(category)" color="success" small
                    >fal fa-check</v-icon
                  >
                  <v-icon v-else color="error" small>fal fa-times</v-icon>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              influencerChannel.categories &&
                influencerChannel.categories.length === 3
            "
            color="success"
            @click="save()"
          >
            Connect media
          </v-btn>
          <v-btn v-else disabled>
            Choose 3 categories
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import local_numbers from "@/helpers/filters/local_numbers";

export default {
  filters: { local_numbers },
  props: {
    influencerChannel: {
      required: true
    },
    bordered: {
      required: false,
      type: Boolean
    }
  },
  data: () => ({
    showConnectDialog: false,
    categories: [],
    channelActivated: false
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    saveButtonText() {
      return this.channelActivated ? "Pending approval" : "Connect";
    }
  },
  methods: {
    ...mapActions("influencer", [
      "getChannelCategories",
      "updateInfluencerChannel"
    ]),

    hasCategory(category) {
      // Filter array to check if the category is selected
      let categoryArr = this.influencerChannel.categories.filter(
        item => item.id === category.id
      );

      // Returns true if the category is selected
      return categoryArr.length !== 0;
    },
    toggleCategory(category) {
      // If the category is selected remove it. Else add
      if (this.hasCategory(category)) {
        // Just override array by filtering current selected array to not include the category
        this.influencerChannel.categories = this.influencerChannel.categories.filter(
          item => item.id !== category.id
        );
      } else {
        // Cap to max 3 categories
        if (
          this.influencerChannel.categories &&
          this.influencerChannel.categories.length >= 3
        ) {
          this.setSnackError("You can only choose 3");
          return;
        }

        this.influencerChannel.categories.push(category);
      }
    },
    save() {
      // Remove reference before saving
      let influencerChannel = {
        uuid: this.influencerChannel.uuid,
        categories: this.influencerChannel.categories.map(category =>
          Number(category.id)
        ),
        active: 1
      };
      // Add active on "local" prop for saving data
      this.updateInfluencerChannel(influencerChannel)
        .then(() => {
          this.channelActivated = true;
          this.setSnackSuccess("Saved");
          this.trackVirtualPageView("channel-connect/success");
          this.showConnectDialog = false;
        })
        .catch(() => {
          this.setSnackError("Something went wrong");
        });
    }
  },
  watch: {
    showConnectDialog(newValue) {
      if (newValue) {
        this.trackVirtualPageView("channel-connect/category-dialog");
      }
    }
  },
  created() {
    this.getChannelCategories().then(data => {
      this.categories = data;
    });
  }
};
</script>
